<template>
	<div>
		<validation-observer v-if="!loading" ref="formRules">
			<b-form>
				<h5 class="mb-75">Project Committee</h5>
				<b-row>
					<!-- cityOfficer -->
					<b-col cols="4">
						<b-form-group label="City officer" label-for="cityOfficer">
							<validation-provider #default="{ errors }" name="City officer" rules="required">
								<v-select
									id="cityOfficer"
									v-model="formData.city_officer_id"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="cityOfficers"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- consultant -->
					<b-col cols="4">
						<b-form-group label="Consultant" label-for="consultant">
							<validation-provider #default="{ errors }" name="Consultant" rules="required">
								<v-select
									id="consultant"
									v-model="formData.consultant_id"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="consultants"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<!-- accountant -->
					<b-col cols="4">
						<b-form-group label="Accountant" label-for="accountant">
							<validation-provider #default="{ errors }" name="Accountant" rules="required">
								<v-select
									id="accountant"
									v-model="formData.accountant_id"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="accountants"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- storeKeeper -->
					<b-col cols="4">
						<b-form-group label="Store keeper" label-for="storeKeeper">
							<validation-provider #default="{ errors }" name="Store keeper" rules="required">
								<v-select
									id="storeKeeper"
									v-model="formData.store_keeper_id"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="storeKeepers"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>

				<h5 class="mb-75 mt-2">Setup Information</h5>
				<!-- number of stores -->
				<b-row>
					<b-col cols="4">
						<b-form-group label="Number of stores" label-for="stores">
							<validation-provider #default="{ errors }" name="Number of stores" rules="required">
								<b-form-input
									id="stores"
									v-model="formData.number_of_stores"
									type="number"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!-- number of sub projects -->
					<b-col cols="4">
						<b-form-group label="Number of sub projects" label-for="sub-projects">
							<validation-provider #default="{ errors }" name="Number of sub projects" rules="required">
								<b-form-input
									id="sub-projects"
									v-model="formData.number_of_sub_projects"
									type="number"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<!-- Bank account number -->
					<b-col cols="4">
						<b-form-group label="Bank account number" label-for="banc-number">
							<validation-provider #default="{ errors }" name="Bank account number" rules="required">
								<b-form-input
									id="banc-number"
									v-model="formData.bank_account_id"
									type="number"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
		<div v-else class="">
			<div class="text-center text-primary mt-3 mb-2">
				<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
				<span class="d-block mt-1">Loading...</span>
			</div>
		</div>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import { BFormInput, BFormGroup, BSpinner, BForm, BRow, BCol } from 'bootstrap-vue';
import { required } from '@validations';
import store from '@/store';

export default {
	name: 'SetupTab',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BSpinner,
		BForm,
		BRow,
		BCol,

		vSelect
	},

	props: {
		value: {
			type: Object,
			require: true
		}
	},

	data: () => ({
		required,
		formData: {
			city_officer_id: '',
			consultant_id: '',
			accountant_id: '',
			store_keeper_id: '',
			number_of_stores: 1,
			number_of_sub_projects: 1,
			bank_account_id: ''
		},

		loading: false,

		cityOfficers: [],
		consultants: [],
		accountants: [],
		storeKeepers: []
	}),

	watch: {
		formData: {
			async handler(val) {
				const success = await this.$refs.formRules?.validate();
				this.$emit('input', {
					...this.value,
					...val,
					number_of_stores: parseInt(val.number_of_stores, 10),
					number_of_sub_projects: parseInt(val.number_of_sub_projects, 10),
					isValid: success
				});
			},
			deep: true,
			immediate: true
		}
	},

	async created() {
		this.loading = true;
		try {
			this.cityOfficers = (await store.dispatch('users/getUsers', { type: 15 })).data;
			this.consultants = (await store.dispatch('users/getUsers', { type: 16 })).data;
			this.accountants = (await store.dispatch('users/getUsers', { type: 13 })).data;
			this.storeKeepers = (await store.dispatch('users/getUsers', { type: 14 })).data;
			this.loading = false;
		} catch (error) {
			console.log(error);
			this.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped></style>
