<template>
	<div>
		<p class="mb-2">List fo sub projects</p>
		<validation-observer ref="formRules">
			<b-form ref="form" class="repeater-form">
				<b-row v-for="(item, i) in items" :id="item.id" :key="item.id" ref="row">
					<b-col md="1">
						<div class="d-flex flex-fill justify-content-center align-items-center" style="height: 100%">
							<span> {{ i + 1 }}</span>
						</div>
					</b-col>
					<b-col md="4">
						<b-form-group label="Sub project Name" label-for="item-name">
							<validation-provider #default="{ errors }" name="Sub project Name" rules="required">
								<b-form-input
									id="item-name"
									v-model="item.name"
									type="text"
									:state="errors.length > 0 ? false : null"
									placeholder="Sub project Name"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="4">
						<b-form-group label="Technician" label-for="technician">
							<validation-provider #default="{ errors }" name="Technician" rules="required">
								<v-select
									id="technician"
									v-model="item.technician_id"
									label="name"
									:options="technicians"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<hr />
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import { required } from '@validations';
import store from '@/store';

export default {
	name: 'SubProjectsTab',

	components: {
		ValidationProvider,
		ValidationObserver,
		BForm,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,

		vSelect
	},

	props: {
		value: {
			type: Object,
			default: () => {}
		},

		count: {
			type: Number,
			default: 1
		}
	},

	data() {
		return {
			items: [],
			technicians: [],

			required
		};
	},

	watch: {
		count: {
			handler() {
				this.items = [];

				// eslint-disable-next-line no-plusplus
				for (let i = 1; i <= this.count; i++) {
					this.items.push({
						id: i + 1,
						name: '',
						technician_id: '',
						project_id: this.$route.params.id
					});
				}
			},
			immediate: true
		},

		items: {
			async handler(val) {
				const success = await this.$refs.formRules.validate();
				this.$emit('input', {
					isValid: success,
					items: val
				});
			},
			deep: true
		}
	},

	async created() {
		this.technicians = (await store.dispatch('users/getUsers', { type: 12 })).data;
	},

	methods: {}
};
</script>

<style lang="scss"></style>
