var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('h5',{staticClass:"mb-75"},[_vm._v("Project Committee")]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"City officer","label-for":"cityOfficer"}},[_c('validation-provider',{attrs:{"name":"City officer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"cityOfficer","label":"name","filterable":false,"searchable":false,"options":_vm.cityOfficers,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.city_officer_id),callback:function ($$v) {_vm.$set(_vm.formData, "city_officer_id", $$v)},expression:"formData.city_officer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3126926375)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Consultant","label-for":"consultant"}},[_c('validation-provider',{attrs:{"name":"Consultant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"consultant","label":"name","filterable":false,"searchable":false,"options":_vm.consultants,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.consultant_id),callback:function ($$v) {_vm.$set(_vm.formData, "consultant_id", $$v)},expression:"formData.consultant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3134870922)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Accountant","label-for":"accountant"}},[_c('validation-provider',{attrs:{"name":"Accountant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"accountant","label":"name","filterable":false,"searchable":false,"options":_vm.accountants,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.accountant_id),callback:function ($$v) {_vm.$set(_vm.formData, "accountant_id", $$v)},expression:"formData.accountant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1493576215)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Store keeper","label-for":"storeKeeper"}},[_c('validation-provider',{attrs:{"name":"Store keeper","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"storeKeeper","label":"name","filterable":false,"searchable":false,"options":_vm.storeKeepers,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.store_keeper_id),callback:function ($$v) {_vm.$set(_vm.formData, "store_keeper_id", $$v)},expression:"formData.store_keeper_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,998693345)})],1)],1)],1),_c('h5',{staticClass:"mb-75 mt-2"},[_vm._v("Setup Information")]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Number of stores","label-for":"stores"}},[_c('validation-provider',{attrs:{"name":"Number of stores","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"stores","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.number_of_stores),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_stores", $$v)},expression:"formData.number_of_stores"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,447531346)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Number of sub projects","label-for":"sub-projects"}},[_c('validation-provider',{attrs:{"name":"Number of sub projects","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sub-projects","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.number_of_sub_projects),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_sub_projects", $$v)},expression:"formData.number_of_sub_projects"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2222419328)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Bank account number","label-for":"banc-number"}},[_c('validation-provider',{attrs:{"name":"Bank account number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"banc-number","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.bank_account_id),callback:function ($$v) {_vm.$set(_vm.formData, "bank_account_id", $$v)},expression:"formData.bank_account_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,959548466)})],1)],1)],1)],1)],1):_c('div',{},[_c('div',{staticClass:"text-center text-primary mt-3 mb-2"},[_c('b-spinner',{staticClass:"align-middle",staticStyle:{"width":"3rem","height":"3rem"}}),_c('span',{staticClass:"d-block mt-1"},[_vm._v("Loading...")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }