<template>
	<div>
		<form-wizard
			color="#8bc53f"
			:title="null"
			:subtitle="null"
			layout="vertical"
			finish-button-text="Complete"
			class="vertical-steps steps-transparent mb-3"
			@on-complete="complate"
		>
			<tab-content title="Project setup" icon="feather icon-settings" :before-change="saveSetup">
				<div class="">
					<h4 class="mb-0">
						Project setup
					</h4>
					<small class="text-muted">
						Fill in project setup information
					</small>
				</div>
				<hr />
				<div class="mt-2">
					<setup-step v-model="setupData" />
				</div>
			</tab-content>
			<tab-content title="Sub Projects" icon="feather icon-list" :before-change="saveSubProjects">
				<div class="mb-2">
					<h4 class="mb-0">
						Sub Projects
					</h4>
					<small class="text-muted">
						Setup sub projects information & coding
					</small>
				</div>
				<div class="mt-2">
					<sub-projects-step v-model="subProjects" :count="setupData.number_of_sub_projects" />
				</div>
			</tab-content>
		</form-wizard>
	</div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { FormWizard, TabContent } from 'vue-form-wizard';
import api from '@/utils/api';
import SetupStep from './steps/setup-step.vue';
import SubProjectsStep from './steps/sub-projects-step.vue';

export default {
	name: 'ProjectSetup',

	components: { FormWizard, TabContent, SetupStep, SubProjectsStep },

	data: () => ({
		setupData: {},
		subProjects: {}
	}),

	methods: {
		async saveSetup() {
			if (!this.setupData.isValid) return false;
			const path = `projects/${this.$route.params.id}/setup`;

			const { data } = await api.call({ path, data: this.setupData, method: this.setupData.id ? 'PUT' : 'POST' });
			this.setupData = data.data;

			return true;
		},

		async saveSubProjects() {
			if (!this.subProjects.isValid) return false;
			const path = `projects/${this.$route.params.id}/sub-projects`;

			await api.call({
				path,
				data: {
					sub_projects: this.subProjects.items
				},
				method: 'POST'
			});

			return true;
		},

		complate() {
			this.$router.push({ name: 'project.cfw.details', params: { id: this.$route.params.id } });
		}
	}
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vue-form-wizard .wizard-tab-content {
	padding: 20px 20px 10px;
}

.vertical-steps.vue-form-wizard .wizard-card-footer {
	margin-left: 206px;
	margin-top: 1rem;
	padding-top: 1rem;
}

.vs__clear {
	padding-left: 8px;
	margin-top: -2px;
}
</style>
